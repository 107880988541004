// app/providers.tsx
"use client";
import { authClient } from "@/src/utils/firebase/firebaseClientConfig";
import { usePathname, useSearchParams } from "next/navigation";
import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";
import { useEffect } from "react";

if (typeof window !== "undefined" && process.env.NEXT_PUBLIC_POSTHOG_KEY) {
	posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY, {
		capture_pageview: false, // Disable automatic pageview capture, as we capture manually
		api_host: "/ingest",
		ui_host: "https://us.posthog.com",
	});

	authClient.onAuthStateChanged((user) => {
		if (user?.uid) {
			posthog.identify(user?.uid, {
				email: user?.email,
			});
		}
	});

	if (process.env.NODE_ENV === "development") {
		posthog.opt_out_capturing();
	}
}

export function PostHogPageview(): JSX.Element {
	const pathname = usePathname();
	const searchParams = useSearchParams();

	useEffect(() => {
		if (pathname) {
			let url = window.origin + pathname;
			if (searchParams?.toString()) {
				url = `${url}?${searchParams.toString()}`;
			}
			posthog.capture("$pageview", {
				$current_url: url,
			});
		}
	}, [pathname, searchParams]);

	return <></>;
}

export function PHProvider({ children }: { children: React.ReactNode }) {
	return <PostHogProvider client={posthog}>{children}</PostHogProvider>;
}
